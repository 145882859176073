<template>
  <div class="wrap">
    <div class="title">{{ $t("activity.title") }}</div>
    <div class="list_wrap">
      <List
        v-model="loading"
        :finished="finished"
        :finished-text="$t('Hint.notMore')"
        :loading-text="$t('Hint.loading')"
        @load="getList"
      >
        <div
          class="list_item"
          v-for="(item, index) in list"
          :key="index"
          @click="toDetails(item)"
        >
          <div class="title">{{ item.title }}</div>
          <div class="time">
            {{ `${$t("activity.time")}: ${format(item.indate)}` }}
          </div>
          <div class="img">
            <img :src="item.picture" alt="" />
          </div>
          <div class="more">{{ $t("toDetails") }}</div>
        </div>
      </List>
    </div>
  </div>
</template>

<script>
import Tabbar from "../../components/Tabbar.vue";
import { List, Toast } from "vant";
import { $get, $post } from "../../utils/request";
import { format } from "../../utils";
export default {
  components: {
    Tabbar,
    List,
    Toast,
  },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      currentPage: 1,
      format,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      if (this.finished) return false;
      this.finished = false;
      if (this.list.length) {
        this.currentPage++;
      }
      try {
        Toast.loading();
        const res = await $get("/content/sale");
        const { ret, msg, data } = res.data;
        this.list = data.lists;
        this.loading = false;
        Toast.clear();
        if (data.currentPage == data.lastPage) {
          this.finished = true;
        }
        // this.configs = data[0];
      } catch (error) {
        Toast.clear();
        this.loading = false;
      }
    },
    toDetails(item) {
      const { url } = item.links[0] || {};
      if (!url) return this.$router.push(`/ActivityDetails?id=${item.id}`);
      location.href = url;
    },
  },
};
</script>

<style lang="less" scoped>
@wrapPadding: calc(12rem / 16);
@comRadius: 4px;
@comBG: linear-gradient(#093958, #022c47);
.wrap {
  @background: var(--black);
  // background-color: @background;
  width: 100vw;
  height: calc(100vh - calc(50rem / 16));
  overflow: scroll;
  position: relative;
  > .title {
    padding: calc(20rem / 16) calc(20rem / 16) 0;
    font-size: calc(24rem / 16);
    font-weight: 600;
    position: sticky;
    background: @background;
    top: 0;
    // color: #333;
    color: var(--gold);
  }
  .list_wrap {
    padding: calc(10rem / 16);
    padding-top: calc(14rem / 16);
    background-color: @background;
    .list_item {
      margin-bottom: calc(20rem / 16);
      padding: calc(18rem / 16) calc(16rem / 16) calc(10rem / 16);
      // background-color: #fff;
      background: var(--moduleColor);
      border-radius: calc(8rem / 16);
      .title {
        font-size: calc(16rem / 16);
        margin-bottom: calc(18rem / 16);
        // color: #333;
        color: var(--gold);
      }
      .time {
        font-size: calc(14rem / 16);
        // color: #999;
        color: #ffffff80;
        margin-bottom: calc(12rem / 16);
      }
      .img {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .more {
        padding: calc(10rem / 16);
        font-size: calc(14rem / 16);
        // color: #333;
        color: var(--light);
      }
    }
  }
  /deep/ .mydiv {
    background: @background;
  }
}
</style>